<template>
	<div>
		<el-dialog :draggable="true" width="40%" top="5vh" v-model="outerVisible" @close="Reset" :title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="封面图">
						<span class="xx">*</span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<img v-if="ruleForm.cover" class="dialog-img" :src="ruleForm.cover" />
							<p v-if="ruleForm.cover" class="dele" @click="dele">删除</p>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl" :on-success="handleAvatarSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="排序">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.sort" placeholder="请输入排序"></el-input>
					</el-form-item>
					<el-form-item label="名称">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入名称"></el-input>
					</el-form-item>
					<el-form-item label="价格">
						<span class="xx">*</span>
						<el-input clearable type="number" v-model="ruleForm.price" placeholder="请输入价格"></el-input>
					</el-form-item>
					<el-form-item label="库存">
						<span class="xx">*</span>
						<el-input type="number" clearable v-model="ruleForm.stock" placeholder="请输入库存"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/spec";
	export default {
		data() {
			return {
				Headers: {
					token: ''
				},
				ActionUrl: '',
				outerVisible: false,
				title: '新增',
				ruleForm: {
					product_id: '',
					name: '',
					price: '',
					cover: '',
					status: 1,
					stock: ""
				},
				// Math.floor(Math.random()*10000)
				imgList: []
			}
		},
		mounted() {
			// 上转图片url
			this.Headers.token = sessionStorage.getItem('token')
			this.Headers.appid = sessionStorage.getItem('appid')
			this.Headers.nonce = sessionStorage.getItem('nonce')
			this.Headers.timestamp = sessionStorage.getItem('timestamp')
			this.Headers.sign =sessionStorage.getItem('sign')
			
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/Upload/FormUpload'
		},
		methods: {
			// 删除轮播图片
			deleImg(index) {
				this.imgList.splice(index, 1)
			},
			// 轮播图片
			imgSuccess(res, file) {
				this.imgList.push(res.data.url)
			},
			// 删除图片
			dele() {
				this.ruleForm.cover = ''
			},
			// 上传图片
			handleAvatarSuccess(res, file) {
				this.ruleForm.cover = res.data.url
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.imgList = res.data.imgpath?res.data.imgpath.split(","):[],
							delete this.ruleForm.create_time
						}
					})
			},
			// 显示隐藏
			show(val, id, product_id) {
				this.title = val
				this.ruleForm.product_id = product_id
				if (id) {
					this.ruleForm.id = id
					this.getinfo()
				}
				this.outerVisible = !this.outerVisible
			},
			// 清空数据
			Reset() {
				this.imgList = []
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				delete this.ruleForm.id
				delete this.ruleForm.delete_time
			},
			//新增/编辑
			submitForm() {
				this.ruleForm.imgpath = this.imgList.join(",")
				if (this.ruleForm.cover === '') {
					ElMessage({
						message: '请上传封面图',
						type: 'warning',
					})
				} else if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入名称',
						type: 'warning',
					})
				} else if (this.ruleForm.price === '') {
					ElMessage({
						message: '请输入价格',
						type: 'warning',
					})
				} else if (this.ruleForm.stock === '') {
					ElMessage({
						message: '请输入库存',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('SubmitForm')
							this.outerVisible = false
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>

<style scoped>
</style>
