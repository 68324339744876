import request from './request'

const api = {
	// 获取列表
	lists: '/mall/ProductAttr/list',
	// 添加
	add: '/mall/ProductAttr/create',
	// 删除
	dele: '/mall/ProductAttr/delete',
	// 编辑
	edit: '/mall/ProductAttr/update',
	// 详情
	info: '/mall/ProductAttr/read',
}

export {
	request,
	api
}
